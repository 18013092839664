<template>
  <v-dialog
      v-model="dialog"
      fullscreen

      transition="custom-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Información del cliente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text style="overflow-y: scroll; max-height: 82vh">

            <v-form ref="form" v-model="valid" lazy-validation>


             <v-card outlined class="pt-0 pb-6 mb-4 card-border" >
              <span class="card-span">Datos personales</span>
                <v-card-text class="">

                  <v-row class="mt-4 my-0 py-0 ">
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4"
                      :class="{ 'color-red': errors['tipoDocumento'] }">Tipo de documento</label>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0 ">
                      <label class="px-6 mx-4" :class="{ 'color-red': errors['numeroDocumento'] }">N° de documento</label>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4"
                      :class="{ 'color-red': errors['nombreApellido'] }"

                        >Nombre y Apellido</label>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1 py-0">
                    <v-col cols="3" class="my-0 py-0 ">

                      <v-select
                        dense
                        outlined
                        :disabled="true"
                        :items="['CUIT', 'DNI', 'Pasaporte']"
                        v-model="form.tipoDocumento"
                        class="px-6 mx-4"
                        :rules="[ $rulesRequerido ]"
                        ref="tipoDocumento"
                        @input="validateField('tipoDocumento')"
                      ></v-select>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0 ">
                      <v-text-field
                        v-model="form.numeroDocumento"
                        dense
                        outlined
                        class="px-6 mx-4"
                        :disabled="disabled"
                        :rules="[
                                  
																	$rulesRequerido,
                                  $rulesCuit,
                                  ruleUniqueCuit
																	
																]"
                        
                        v-mask="'##-########-#'"
                        ref="numeroDocumento"
                        :error-messages="customErrorMessage"
                        @input="validateField('numeroDocumento')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="my-0 py-0 ">
                      <v-text-field
                        v-model="form.nombreApellido"
                        dense
                        outlined

                         class="px-6 mx-4"
                        :disabled="disabled"
                        :rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax50,
																]"
                        ref="nombreApellido"
                        @input="validateField('nombreApellido')"

                      ></v-text-field>
                    </v-col>

                  </v-row>

                </v-card-text>
              </v-card>
              <br>
              <br>

             <v-card outlined class="pt-0 pb-6 mb-4 card-border" >
              <span class="card-span">Datos de dirección</span>
                <v-card-text>
                   <v-row class="mt-4 my-0 py-0 ">
                      <v-col cols="3" class="my-0 py-0">
                        <label class="px-6 mx-4" :class="{ 'color-red': errors['calle'] }">Calle</label>
                      </v-col>
                      <v-col cols="3" class="my-0 py-0">
                        <label class="px-6 mx-4" :class="{ 'color-red': errors['numero'] }">Número</label>
                      </v-col>
                      <v-col cols="3" class="my-0 py-0">
                        <label class="px-6 mx-4" :class="{ 'color-red': errors['piso'] }">Piso</label>
                      </v-col>
                      <v-col cols="3" class="my-0 py-0">
                        <label class="px-6 mx-4" :class="{ 'color-red': errors['depto'] }">Depto</label>
                      </v-col>
                  </v-row>
                  <v-row class="mt-1 py-0">
                      <v-col cols="3" class="my-0 py-0 ">

                        <v-text-field

                          v-model="form.calle"
                          dense
                          outlined

                          class="px-6 mx-4"
                          :disabled="disabled"
                          :rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax50,
																]"
                          ref="calle"
                          @input="validateField('calle')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" class="my-0 py-0">
                        <v-text-field
                          type="number"
                          v-model="form.numero"
                          dense
                          outlined

                          class="px-6 mx-4"
                          :disabled="disabled"
                          :rules="[
																	$rulesRequerido,
																	$rulesNumericos
																]"
                          ref="numero"
                          @input="validateField('numero')"

                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" class="my-0 py-0">
                        <v-text-field
                          type="number"
                          v-model="form.piso"
                          dense
                          outlined

                          class="px-6 mx-4"
                          :disabled="disabled"
                          :rules="[
																	$rulesAlfaNum,
																	$rulesMax50,
																]"
                          ref="piso"
                          @input="validateField('piso')"

                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" class="my-0 py-0 ">
                        <v-text-field
                          v-model="form.depto"
                          dense
                          outlined

                          :disabled="disabled"
                          class="px-6 mx-4"
                          :rules="[
																	$rulesAlfaNum,
																	$rulesMax50,
																]"
                          ref="depto"
                          @input="validateField('depto')"
                        ></v-text-field>
                      </v-col>
                  </v-row>

                  <v-row class="mt-6 my-0 pt-3 ">
                    <v-col cols="3" class="my-0 py-0 ">
                      <label class="px-6 mx-4" :class="{ 'color-red': errors['pais'] }">Pais</label>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4" :class="{ 'color-red': errors['provincia'] }">Provincia</label>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4" :class="{ 'color-red': errors['localidad'] }">Localidad</label>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4 " :class="{ 'color-red': errors['cp'] }">C.P.</label>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1 py-0">
                    <v-col cols="3" class="my-0 py-0">

                      <v-text-field

                        v-model="form.pais"
                        dense
                        outlined

                        class="px-6 mx-4"
                        :disabled="true"
                        :rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax50,
																]"
                        ref="pais"
                        @input="validateField('pais')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <v-autocomplete
                        :items="provincias"
                        item-value="id"
                        item-text="nombre"
                        v-model="form.provincia"
                        :rules="[$rulesRequerido]"                        
                        return-object
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="3" class="my-0 py-0">
                       <v-text-field
                        v-model="form.localidad"
                        dense
                        outlined

                        class="px-6 mx-4"
                        :disabled="disabled"
                        :rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax50,
																]"
                        ref="localidad"
                        @input="validateField('localidad')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="my-0 py-0">
                       <v-text-field
                        type="number"
                        v-model="form.cp"
                        dense
                        outlined
                        class="px-6 mx-4"
                        :disabled="disabled"
                        :rules="[
																	$rulesRequerido,
																	$rulesNumericos,
																	$rulesMax50,
																]"
                         ref="cp"
                        @input="validateField('cp')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
              <br>
              <br>
              <v-card outlined class="pt-0 pb-6 mb-4 card-border" >
              <span class="card-span">Datos de contacto</span>
                <v-card-text>

                  <v-row class="mt-4 my-0 py-0 ">
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4" :class="{ 'color-red': errors['telefono'] }">Teléfono</label>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4" :class="{ 'color-red': errors['celular'] }">Celular</label>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <label class="px-6 mx-4" :class="{ 'color-red': errors['mail'] }">Mail</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="my-0 py-0">

                      <v-text-field
                        type="number"
                        v-model="form.telefono"
                        dense
                        outlined

                        class="px-6 mx-4"
                        :disabled="disabled"
                        :rules="[
																	$rulesNumericos,
																	$rulesMax50,
																]"
                        ref="telefono"
                        @input="validateField('telefono')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                      <v-text-field
                        type="number"
                        v-model="form.celular"
                        dense
                        outlined

                        class="px-6 mx-4"
                        :disabled="disabled"
                        :rules="[
																	$rulesRequerido,
																	$rulesNumericos,
																	$rulesMax50,
																]"
                        ref="celular"
                        @input="validateField('celular')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="my-0 py-0">
                       <v-text-field
                        v-model="form.mail"
                        dense
                        outlined

                        class="px-6 mx-4"
                        :disabled="disabled"
                        :rules="[
																	$rulesRequerido,
																	$rulesMail,
																	$rulesMax50,
																]"
                         ref="mail"
                        @input="validateField('mail')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="validate" v-show="!disabled">Guardar</v-btn>
        </v-card-actions>
        <dialog-form-validate
              v-model="dialogs['formValidate']"
              @input="dialogs['formValidate'] = $event"
            />
        <dialog-form-completed
              v-model="dialogs['formCompleted']"
              @input="dialogs['formCompleted'] = $event"
            />
      </v-card>
    </v-dialog>
</template>

<style scoped>

.card-border{
  border: 1.5px solid #DCDCDC !important
}
.card-span{
    margin-top: -15px;
    position: absolute;
    margin-left: 23px;
    background: white;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 18px;
    font-weight: 500;

}
.custom-btn {
  text-transform: none; /* Elimina la transformación de mayúsculas */
  font-family: inherit; /* Hereda el tipo de letra por defecto */
}

.capitalize-first::first-letter {
  text-transform: capitalize; /* Solo la primera letra en mayúscula */
}
</style>
<script>

import DialogFormValidate from "@/views/dialogs/FormValidate.vue";
import DialogFormCompleted from "@/views/dialogs/FormCompleted.vue";

export default {
  components: {

    DialogFormValidate,
    DialogFormCompleted,
  },
  props: {
    form: {
      type: Object,
     required: true
    },
     errors: {
      type: Object,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customErrorMessage: "", // Mensaje de error personalizado
      dialog: this.value,
      dialogs: {

        formValidate: false,
        formCompleted: false,
      },
      valid: true,
      provincias: [],

    };
  },
  watch: {
    form: function () {
      this.form.pais = 'ARGENTINA';
      this.form.tipoDocumento = 'CUIT';
    },
    value(val) {
      this.dialog = val;

      if(this.dialog)
      {
         this.resetAllValidations();

      }
    },
    dialog(val) {
      this.$emit('input', val);
    },
  },
  created() {
    
    this.$getData([ "provincias"]);
    //this.setDatosPrueba();
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async ruleUniqueCuit(value) {
      
      if (typeof value !== "undefined" && value != null && value !== "") {
        this.customErrorMessage = "";
        let resp = await this.$isClient(value,'A80',process.env.VUE_APP_DB_MJ);
      
        switch (resp) {
          case 1:
            console.log("Cuit en uso");
            this.customErrorMessage = "Este CUIT ya está en uso";
            this.numeroDocumento= "";
            return false;

          case -1:
            console.log("Cuit invalido");
            this.customErrorMessage = "CUIT inválido";
            this.numeroDocumento = "";
            return false;

          default:
            this.customErrorMessage = "";
            return true;
        }
        
      } else {
        this.customErrorMessage = "";
      }
    },
     async validate() {
          //hacer validaciones sino cumple, muestra modal

          if (this.$refs.form.validate() && this.valid) {
          /*console.log("this.prueba");
          console.log(this.prueba);
          console.log("buscando locationExternalCode");*/
          let locationExternalCode = await this.$getLocationExternalCode(
            this.form.provincia.id
          );
          /*console.log("locationExternalCode");
          console.log(locationExternalCode);*/
          const data = {
            CardName: this.form.nombreApellido || null,
            FederalTaxID: this.form.numeroDocumento.replace(/-/g, "") || null,
            Cellular: this.form.celular || null,
            EmailAddress: this.form.mail || null,
            Phone1: this.form.telefono || null,
            U_UnamePortal: `A80${this.form.numeroDocumento.replace(/-/g, "")}` || null,
            U_NamePortal: `A80${this.form.numeroDocumento.replace(/-/g, "")}` || null,
           
            CardCode: `A80${this.form.numeroDocumento.replace(/-/g, "")}` || null,
            CardType: "cLid",
          };
          data.BPAddresses = [
              {
                AddressName: "Entrega",
                /*Country: this.form.pais || null,*/
                Country: 'AR', //o el codigo del pais
                State: locationExternalCode || null,
                City: this.form.localidad || null,
                Street: this.form.calle || null,
                StreetNo: this.form.numero || null,
                ZipCode: this.form.cp || null,
                AddressType: "bo_ShipTo"
              }
            ]
            Swal.alertGetInfo("Registrando datos del Cliente");
          let response = await this.senForm(data);
          //console.log('respuesta');
          //console.log(response);
          
          if(response == 201){
            Swal.close();
            this.dialogs.formCompleted =true;
          }
          else{
            Swal.close();
            Swal.alertError();
          }
        }
        else
        {
          for (const key in this.$refs) {
          const ref = this.$refs[key];
           this.validateField(ref);
          }
        }
    },
 async senForm(data) {
      try {
        const response = await this.$axiosApi.post(
            "crearEditarClientes",
            data
          );
          return response.data.code;        
       
      } catch (error) {
        console.error('Error fetching client:', error);
        return error;        
      }
    },
    validateField(refs) {
       const field = this.$refs[refs];

      if (field) {
        this.errors[refs] = !field.validate(); // Cambia el estado según la validación
      }

    },

    resetAllValidations() {

        for (const key in this.$refs) {
          const ref = this.$refs[key];
          // Verifica si el ref tiene el método resetValidation
          if (ref && typeof ref.resetValidation === 'function') {
            ref.resetValidation();
          }
        }
    }

  },
};
</script>
